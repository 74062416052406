import React, { Component } from 'react';
import SectionHeader from './../component/section-header'
import BlogGrid3Col from './../component/blog/blog-grid-3col'
import {Link} from 'react-router-dom'
import './../css/blog-section.css';

// header section content
const title = <h2 className="title">Okala Template comes with<br/>awesome widgets.</h2>;
const subtitle = <h6 className="subtitle">Over 8000 Customers Trust Us, Why won’t You?</h6>;
const desc = <p className="desc mb-0">To get uptodate about our new projects and research get subscribe to our blog. All discounts and products reviews of Okala will be available here.</p>
const styleClass = 'bz-section-header text-left pb--30 pb_lg--40';

class BlogSection extends Component {
    render() {
        return (
            <section className="blog-section  pt--60 pb--60 pt_lg--100 pb_lg--100">
                <div className="container p-0">
                    <div className="col-11 col-lg-5 m-auto ml-lg-0">
                    <SectionHeader 
                        subtitle={ subtitle }
                        title={ title }
                        desc={desc}
                        styleClass={styleClass}
                    />
                    </div>
                </div>
                <div className="container">
                    <BlogGrid3Col itemNumber={3}/>
                    <div className="col-12 pt-5 text-center">
                        <Link to="blog-page" className="da-custom-btn btn-border-radius40"><span>View More</span></Link>
                    </div>
                </div>
            </section>
        )
    }
}

export default BlogSection;