import React, { Component } from 'react';
// import { Tabs, Tab } from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './../css/component/service-tab.css';
import { FaLaravel, FaBuffer, FaDelicious, FaGitlab, FaAngleDoubleRight, FaAlipay, FaMobile, FaUikit, FaSitemap, FaPage4, FaWeebly, FaDesktop, FaCubes } from "react-icons/fa";
import { Link } from 'react-router-dom';

import { withTranslation } from 'react-i18next';



class ServiceTab extends Component {
    render() {
        const { t } = this.props;
        const ServiceInfoList = [
            {
                icon: <FaMobile />,
                name: t('servicesSectionModelDevelopmentTitle'),
                desc: t('servicesSectionModelDevelopmentParagraph'),
                imgUrl: 'assets/images/service/mobiledevelopment.jpg',
                imgAlt: 'Mobile Develpoment'
            },
            {
                icon: <FaDesktop />,
                name: t('sevicesSectionWebDesignTitle'),
                desc:  t('servicesSectionWebDesignParagraph'),
                imgUrl: 'assets/images/service/webdesign.jpg',
                imgAlt: 'Web Design'
            },
            {
                icon: <FaCubes />,
                name: t('servicesSection3DModelingTitle'),
                desc:  t('servicesSection3DModelingParagraph'),
                imgUrl: 'assets/images/service/3dmodeling.jpg',
                imgAlt: '3D Modeling'
            },
        
        ]
        // const title = this.state.tabHeadList.
        return (
            <Tabs className="bz-service-area">
                <TabList>
                    {ServiceInfoList.map((val , i) => (
                        <Tab key={i}>
                            <span className="icon">{val.icon}</span>
                            <span className="text">{val.name}</span>
                        </Tab>
                    ))}
                </TabList>

                {ServiceInfoList.map((val , i) => (
                <TabPanel key={i}>
                    <div className="service-content">
                        <div className="service-body-container d-lg-flex flex-wrap">
                            <div className="sc-left">
                                <div className="service-image">
                                    {(val.imgUrl ?
                                    <img
                                        className="lazy"
                                        src={`${val.imgUrl}`} 
                                        alt={`${val.imgAlt}`}
                                     />
                                     : ""
                                     )}
                                </div>
                            </div>
                            <div className="sc-right">
                                { ( val.name ? <h5>{val.name}</h5> : "") }
                                { ( val.desc ? <p>{val.desc}</p> : "") }
                            </div>
                        </div>
                    </div>
                </TabPanel>
                ))}
            </Tabs>
        )
    }
}

export default withTranslation()(ServiceTab);