import React, { Component } from 'react';
import { FaCheckCircle } from "react-icons/fa";
import './../css/about-project-section.css';
import { withTranslation } from 'react-i18next';
class AboutProjectSection extends Component {
    render() {
        const { t } = this.props;
        return (
            <section id='project' className="about-project-section pt--60 pb--60 pt_lg-100 pb_lg--100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 pb-5 pb-lg-0">
                            <div className="bz-section-header">
                                <h2 className="title"> {t('techStackSectionTitle')}</h2>
                                <p className="desc mb-3">{t('techStackSectionSubtitle')}</p>
                                <ul className="list-unstyled pl-0 mb-0">
                                    <li><FaCheckCircle/>Xamarin Forms</li>
                                    <li><FaCheckCircle/>React Native</li>
                                    <li><FaCheckCircle/>React</li>
                                    <li><FaCheckCircle/>.Net</li>
                                    <li><FaCheckCircle/>{t('techStackSectionLastBulletPoint')}</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-7">
                            <div className="image">
                                <img 
                                    src="assets/images/about/inner-01.jpg" 
                                    alt="image"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default withTranslation()(AboutProjectSection);