import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import './../css/action-section.css';


class ActionSection extends Component {
    render() {
        return (
            <section className="action-section style2 pt--60 pb--60 pt_lg--100 pb_lg--100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 p-0">
                            <div className="action-content text-center mb-4 mb-lg-0 text-lg-left">
                                <h4 className="title"> Start building beautiful pages in your browser Come and join us.</h4>
                            </div>
                        </div>
                        <div className="col-lg-4 text-center text-lg-right p-0">
                            <Link to="/pricing-page" className="da-custom-btn btn-border-radius40 btn-solid"><span>Purchase Plan</span></Link>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ActionSection;