import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import './../css/component/client-logo.css';
import 'swiper/swiper.scss';

const clientBrandList = [
    {
        imagUrl: "01",
        imageAlt: 'cimage1'
    },
    {
        imagUrl: "02",
        imageAlt: 'cimage2'
    },
    {
        imagUrl: "03",
        imageAlt: 'cimage3'
    },
    {
        imagUrl: "01",
        imageAlt: 'cimage4'
    },
    {
        imagUrl: "03",
        imageAlt: 'cimage5'
    },
    {
        imagUrl: "02",
        imageAlt: 'cimage6'
    }
]

class ClientBrand extends Component {
    render() {
        return (
            <Swiper className="client-container"
                spaceBetween={0}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                loop={true}
                autoplay= {500}
                breakpoints={{
                    // when window width is >= 640px
                    400: {
                      slidesPerView: 1,
                    },
                    // when window width is >= 640px
                    640: {
                        // width: 640,
                        slidesPerView: 3,
                      },
                    // when window width is >= 768px
                    768: {
                    //   width: 768,
                      slidesPerView: 4,
                    },
                    // when window width is >= 990px
                    990: {
                        // width: 990,
                        slidesPerView: 5,
                      },
                  }}
                >
                {clientBrandList.map((val , i) => (
                    <SwiperSlide key={i}>
                        <div className="client-logo">
                            <img
                                src={`assets/images/banner/client/${val.imagUrl}.png`} alt={`${val.imageAlt}`}
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        )
    }
}

export default ClientBrand;