import React, { Component } from 'react';
import './../css/component/contact-info.css';
import {FaMobileAlt, FaMap, FaEnvelope} from 'react-icons/fa'

import { withTranslation } from 'react-i18next';

class ContactInfo extends Component {
    render() {
        const { t } = this.props;
        return (
            <div className="contact-info">
                <div className="contact-item pt--20 pb--20">
                    <div className="contact-icon">
                        <FaMap />
                    </div>
                    <div className="contact-content">
                        <h6 className="name"> {t('contactSectionAddress')}</h6>
                        <p>17 Howard Close,
                            Daventry NN11 4TD,
                            United Kingdom</p>
                    </div>
                </div>

                <div className="contact-item pt--20 pb--20">
                    <div className="contact-icon">
                        <FaMobileAlt />
                    </div>
                    <div className="contact-content">
                        <h6 className="name"> {t('contactSectionCallUs')}</h6>
                        <p>+447713357361 <br/> </p>
                    </div>
                </div>

                <div className="contact-item pt--20 pb--20">
                    <div className="contact-icon">
                        <FaEnvelope />
                    </div>
                    <div className="contact-content">
                        <h6 className="name">Email</h6>
                        <p>info@bluehashstudio.com</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(ContactInfo);