import React, { Component, Fragment } from 'react';
import './../css/app.css';
import Header from './../sections/header';
import PageHeader from './../sections/page-header'
import SectionHeader from './../component/section-header'
import ActionSection from './../sections/action-section'
import ContactArea from './../sections/contact-area'
import Footer from './../sections/footer'
import './../css/contact-page.css'
// header section content
const title = <h2 className="title">Ordinary people<br />with Awesome Skills</h2>;
const desc = <p className="desc pb--30 mb-0">We are a strategic, creative studio based out of Savannah, Georgia. Our close team of innovative professionals is passionate about partnering with good people and organizations to design exceptional custom solutions. We approach each project and design challenge love to help.</p>
const styleClass = 'bz-section-header inner-style2';
class ContactPage extends Component {
  render(){
    
    return (
        <Fragment> 
            <Header styleClass={'header style2 transparent-header'}/>
            <PageHeader 
                    pageTitle={'Contact Page'} 
                    pageDesc={'We help your brand to be recognizable'}
                    styleClass={'page-header style2 contact-page'}
                />
            <section className="inner-page contact-page">
                <div className="inner-page-header">
                    <div className="container pl-5 pr-5 p-lg-0 mb--40">
                        <SectionHeader
                            title={ title }
                            desc={desc}
                            styleClass={styleClass}
                        />
                    </div>

                    <div className="container">
                        <ContactArea />
                    </div>
                </div>
            </section>
            <div className="map-area">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d52099.64734914426!2d144.87148289359692!3d-37.8292414086121!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1579514515144!5m2!1sen!2sbd" allowFullscreen=""></iframe>
            </div>
            <ActionSection />
            <Footer />
        </Fragment> 
    );
  }
}

export default ContactPage;