import React, { Component } from 'react';
import './../css/component/section-header.css';

class SectionHeader extends Component {
    render() {
        return (
            <div className={ ( this.props.styleClass ? this.props.styleClass : "") }>
                { this.props.subtitle }
               { this.props.title }
               { this.props.desc }
                { this.props.link }
            </div>
        )
    }
}

export default SectionHeader;