import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaStar } from "react-icons/fa";
import './../css/component/testimonial.css';
import 'swiper/swiper.scss';

const testimonialList = [
    {
        imagUrl: "01",
        imageAlt: 'author1',
        text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore etiam processus ',
        name: 'Krzysztof Szkudniewski',
        designation: 'Leasing & Financial'
    },
    {
        imagUrl: "02",
        imageAlt: 'author2',
        text: 'Blue Hash Studio has provided great services for the development of both our iOS and Android Mobile Applications. I would highly recommend!',
        name: 'Gurjot Singh',
        designation: 'Business Development'
    },
    {
        imagUrl: "03",
        imageAlt: 'author3',
        text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore etiam processus ',
        name: 'Małgorzata Bobko',
        designation: 'Designer'
    },

]


class Testimonial extends Component {
    render() {
        return (
            <Swiper className="da-testimonial-container"
                spaceBetween={30}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                loop={true}
                breakpoints={{
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 30
                    }
                  }}
                >
                {testimonialList.map((val , i) => (
                    <SwiperSlide key={i}>
                        <div className="testimonial-body d-flex flex-wrap justify-content-between">
                            <div className="author-image">
                                <img
                                    src={`assets/images/testimonial/${val.imagUrl}.png`} alt={`${val.imageAlt}`}
                                 />
                            </div>
                            <div className="testimonial-content">
                                <div className="rating">
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                </div>
                                <p className="text">{val.text}</p>
                                <h6 className="name">{val.name}</h6>
                                <p className="designation mb-0">{val.designation}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        )
    }
}

export default Testimonial;