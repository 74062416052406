import React, { Component } from 'react';
import BannerSlider from './../component/banner-slider'
import ClientBrand from './../component/client-logo'
import './../css/banner.css';
import { withTranslation } from 'react-i18next';

class Banner extends Component {
    render() {
        return (
            <section id='home' className="banner banner-slider">
                <BannerSlider />
                {/* <div className="our-client-area">
                    <div className="our-client">
                        <h6 className="name">Trusted By</h6>
                        <div className="client">
                            <ClientBrand />
                        </div>
                    </div>
                </div> */}
            </section>
        )
    }
}

export default withTranslation()(Banner);