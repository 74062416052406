import React, { Component } from 'react';
import ServiceTab from './../component/service-tab'
import SectionHeader from './../component/section-header'

import { withTranslation } from 'react-i18next';
// header section content
const title = <h2 className="title">Sprawdź usługi, w których się specjalizujemy</h2>;
const subtitle = <h6 className="subtitle">Services</h6>;

const styleClass = 'bz-section-header';

class ServiceSection extends Component {
    render() {
        const { t } = this.props;
        return (
            <section id='service' className="service-section bg-ash borderbottom pt--60 pb--60 pt_lg--100 pb_lg--100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <SectionHeader 
                                subtitle={ <h6 className="subtitle">{t('servicesSectionSmallTitle')}</h6> }
                                title={ <h2 className="title">{t('servicesSectionTitle')}</h2> }

                            />
                        </div>                  
                        <div className="col-lg-1"></div>                    
                        <div className="col-lg-5 text-center text-lg-left">
                            <p>{t('servicesSectionParagraph')} </p>
                        </div>                  
                    </div>
                </div>
                <div className="container">
                    <div className="borderbottom"></div>
                </div>
                <div className="container">
                    <ServiceTab />
                </div>
            </section>
        )
    }
}

export default withTranslation()(ServiceSection);