import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import './../css/portfolio.css';

const ProjectList = [
    {
        name: 'Mobile Development',
        projectItemList: [
            {
                title: 'Reach',
                imgUrl: 'assets/images/project/01.jpg',
                imgAlt: 'Mobile Development',
            },
            {
                title: 'Delion',
                imgUrl: 'assets/images/project/02.jpg',
                imgAlt: 'Mobile Development',
            },
            {
                title: 'Scanu',
                imgUrl: 'assets/images/project/03.jpg',
                imgAlt: 'Mobile Development',
            },
            {
                title: 'MojeKorki',
                imgUrl: 'assets/images/project/04.jpg',
                imgAlt: 'Mobile Development',
            },
        ]
    },
    /*{
        name: 'Web Design',
        projectItemList: [
            {
                title: 'Web Design1',
                imgUrl: 'assets/images/project/01.jpg',
                imgAlt: 'Web Design',
            },
            {
                title: 'Web Design2',
                imgUrl: 'assets/images/project/04.jpg',
                imgAlt: 'Web Design',
            },
            {
                title: 'Web Design3',
                imgUrl: 'assets/images/project/03.jpg',
                imgAlt: 'Web Design',
            },
            {
                title: 'Web Design4',
                imgUrl: 'assets/images/project/05.jpg',
                imgAlt: 'Web Design',
            },

        ]
    },*/
    
]
const ProjectList2 = [
    
    {
        name: '3D Modeling',
        projectItemList: [
            {
                title: '3D Modeling',
                imgUrl: 'assets/images/project/3d01.png',
                imgAlt: '3D Modeling',
            },
            {
                title: '3D Visualization',
                imgUrl: 'assets/images/project/3d02.png',
                imgAlt: '3D Visualization',
            },
        ]
    },
    
]



class PortfolioContainer extends Component {
    render() {
        return (
            <Tabs className="project-container">
                <div className="container p-0">
                    <TabList>
                        {ProjectList.map((val , i) => (
                            <Tab key={i}>
                                <span className="text">{val.name}</span>
                            </Tab>
                        ))}
                        {ProjectList2.map((val , i) => (
                            <Tab key={i}>
                                <span className="text">{val.name}</span>
                            </Tab>
                        ))}
                    </TabList>
                </div>

                {ProjectList.map((val , i) => ( 
                <TabPanel key={i}>
                    <div className="project-item-list">
                        <div className="row m-0">
                            {val.projectItemList.map((project, index) =>(
                                <div className="col-md-7 col-lg-2 mx-auto" key={index}>
                                    <div className="project-item">
                                        <div className="project-thumb">
                                            <Link to="portfolio-single"><img className="lazy" 
                                                src={project.imgUrl}
                                                alt={project.imgUrl}
                                            /></Link>
                                            <div className="project-content">
                                                <div className="project-content-inner">
                                                    <p className="cata">{val.name}</p>
                                                    <h6 className="title"><Link to="portfolio-single">{project.title}</Link></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </TabPanel>
                ))}

{ProjectList2.map((val , i) => ( 
                <TabPanel key={i}>
                    <div className="project-item-list">
                        <div className="row m-0">
                            {val.projectItemList.map((project, index) =>(
                                <div className="col-md-2 col-lg-5 mx-auto" key={index}>
                                    <div className="project-item">
                                        <div className="project-thumb">
                                            <Link to="portfolio-single"><img className="lazy" 
                                                src={project.imgUrl}
                                                alt={project.imgUrl}
                                            /></Link>
                                            <div className="project-content">
                                                <div className="project-content-inner">
                                                    <p className="cata">{val.name}</p>
                                                    <h6 className="title"><Link to="portfolio-single">{project.title}</Link></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </TabPanel>
                ))}
            </Tabs>
        )
    }
}

export default PortfolioContainer;