import React, { Component } from 'react';
import SectionHeader from './../component/section-header'
import CountUp from "react-countup";
// import { FaAngleDoubleRight } from "react-icons/fa";
import './../css/cs-section.css';


// header section content
const title = <h2 className="title">Customer satisfaction <br/>is our first priority</h2>;
const subtitle = <h6 className="subtitle">Some important facts</h6>;
const desc = <p className="desc pb--30 mb-0">Okala proudly announced that we are active for more than 5 years in the field of business. From the start we have completed 2000+ projects. Okala is also gifted with 125+ happy clients who are totally satisfied with our services. We are lucky to be a part of this huge client base!</p>
const styleClass = 'bz-section-header pl_lg--60';

class CustomerSatisfection extends Component {
    render() {
        return (
            <section className="bz-customer-satisfection-section borderbottom pt--60 pb--60 pt_lg--100 pb_lg--100">
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-11 col-lg-5 p-0 mt-lg-5 order-lg-last">
                            <SectionHeader 
                                subtitle={ subtitle }
                                title={ title }
                                desc={desc}
                                styleClass={styleClass}
                            />
                        </div>
                        <div className="col-lg-7 p-0 order-lg-first">
                            <div className="image">
                                <img className="thumbnail" src="assets/images/customer-satisfection/img1.jpg" alt="image"/>
                            </div>
                        </div>
                    </div>
                    <div className="bz-counter-up">
                        <div className="counter-item">
                            <div className="count-number"><span className="counter"><CountUp end={125} /></span></div>
                            <span className="text">Happy Clients</span>
                        </div>

                        <div className="counter-item">
                            <div className="count-number">+<span className="counter"><CountUp end={2000} /></span></div>
                            <span className="text">Finished Projects</span>
                        </div>

                        <div className="counter-item">
                            <div className="count-number"><span className="counter"><CountUp end={5} /></span> Years</div>
                            <span className="text">Active</span>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default CustomerSatisfection;