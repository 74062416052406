import React, { Component } from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaVimeoV } from "react-icons/fa";
import {Link} from 'react-router-dom'
import {Link as LinkScroll }  from 'react-scroll'
import './../css/footer.css';

import { withTranslation } from 'react-i18next';
class Footer extends Component {
    
    render() {
        const { t } = this.props;
        return (
            <footer className="footer footer1">
                <div className="footerbottom">
                    <div className="container">
                        <div className="row justify-content-center justify-content-md-between align-items-center" >
                            <ul className="social-media-list d-flex flex-wrap m-0 p-0 list-unstyled">
                            <li><LinkScroll spy={true} smooth={true}  to="home">{t('homeFooterButton')}</LinkScroll></li>
                                <li><LinkScroll spy={true} smooth={true}  to="about">{t('aboutUsFooterButton')}</LinkScroll></li>
                                <li><LinkScroll spy={true} smooth={true}  to="portfolio">Portfolio</LinkScroll></li>
                                <li><LinkScroll spy={true} smooth={true}  to="contact">{t('contactFooterButton')}</LinkScroll></li>
                            </ul>
                            <ul className="social-media-list d-flex flex-wrap m-0 p-0 list-unstyled">
                                <li><Link to="https://www.facebook.com/fluxthemeOfficial/"><FaFacebookF /></Link></li>
                                <li><Link to="https://twitter.com/fluxtheme"><FaTwitter /></Link></li>
                                <li><Link to="https://www.linkedin.com/company/flux-theme/"><FaLinkedinIn /></Link></li>
                                <li><Link to="https://www.instagram.com/FluxThemeOfficial/"><FaInstagram /></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default withTranslation()(Footer);