import React, { Component, Fragment } from 'react';
import './../css/app.css';
import Header from './../sections/header';
import PageHeader from './../sections/page-header'
import SectionHeader from './../component/section-header'
import ActionSection from './../sections/action-section'
import BlogGrid3Col from './../component/blog/blog-grid-3col'
import Pagination from './../component/pagination'
import Footer from './../sections/footer'
// header section content
const title = <h2 className="title">Latest<br />From the Blog</h2>;
const desc = <p className="desc pb--30 mb-0">We are a strategic, creative studio based out of Savannah, Georgia. Our close team of innovative professionals is passionate about partnering with good people and organizations to design exceptional custom solutions. We approach each project and design challenge love to help.</p>
const styleClass = 'bz-section-header inner-style2';
class BlogGridPage extends Component {
  render(){
    
    return (
        <Fragment> 
            <Header styleClass={'header style2 transparent-header'}/>
            <PageHeader 
                    pageTitle={'Blog Page'} 
                    pageDesc={'We help your brand to be recognizable'}
                    styleClass={'page-header style2 blog'}
                />
            <div className="blogpage innerpage">
                <section className="inner-page about-page">
                    <div className="inner-page-header">
                        <div className="container pl-5 pr-5 p-lg-0 mb--40">
                            <SectionHeader
                                title={ title }
                                desc={desc}
                                styleClass={styleClass}
                            />
                        </div>

                        <div className="container">
                            <BlogGrid3Col />
                            <Pagination />
                        </div>
                    </div>
                </section>
            </div>
            <ActionSection />
            <Footer />
        </Fragment> 
    );
  }
}

export default BlogGridPage;