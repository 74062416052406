import React, { Component } from 'react';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import './../css/component/workingprocess.css';



const stepList = [
    {
        processCount: "01",
        ProcessName: 'Requirement analysis',
        ProcessDesc: 'We communicate with clients, sit for meetings, gather all detailed data & analysis the requirements.'
    },
    {
        processCount: "02",
        ProcessName: 'Define variables',
        ProcessDesc: 'Here variables of all projects are defined, UX is guideline prepared and the product is roughly designed.'
    },
    {
        processCount: "03",
        ProcessName: 'Analysis & development',
        ProcessDesc: 'The rough sketch is analyzed thoroughly and submit the report, then product development started.'
    },
    {
        processCount: "04",
        ProcessName: 'Quality assurance',
        ProcessDesc: 'Our experienced QA team checks the quality of the product continuously and suggest improvements'
    },
    {
        processCount: "05",
        ProcessName: 'Complete & delivery',
        ProcessDesc: 'Product is delivered to the client after completing it successfully. Client satisfaction is all'
    },
    {
        processCount: "06",
        ProcessName: 'Research & evolution',
        ProcessDesc: 'The finished product is given to the data analysis team for further improvement and research.'
    },
]

class WorkingProcess extends Component {
    render() {
        const params = {
            Swiper,
            containerClass: 'bz-working-process-container',
            slidesPerView: 1,
              spaceBetween: 20,
              slidesOffsetBefore: 1,
              autoplay: {
                delay: 5500,
                disableOnInteraction: false
              },
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              },
              pagination: {
                el: '.swiper-pagination',
                type: 'progressbar',
                clickable: true
              },
              breakpoints: {
                  990: {
                      slidesPerView: 3,
                      spaceBetween: 30
                  },
                  768: {
                      slidesPerView: 2,
                      spaceBetween: 20
                  },
                  550: {
                      slidesPerView: 1,
                      spaceBetween: 10
                  }
              }
        }
        return (
            <Swiper {...params}>
            {stepList.map((val , i) => (
                <div className="swiper-slide" key={i}>
                    <div className="bz-process-item">
                    <Flippy
                        flipOnHover={true} // default false
                        flipOnClick={true} // default false
                        flipDirection="vertical" // horizontal or vertical
                        ref={(r) => this.flippy = r} // to use toggle method like this.flippy.toggle()
                        // if you pass isFlipped prop component will be controlled component.
                        // and other props, which will go to div
                        style={{ width: '100%', height: '200px' }} /// these are optional style, it is not necessary
                    >
                        <FrontSide>
                            <span className="process-count">{val.processCount}</span>
                            <h6 className="name">{val.ProcessName}</h6>
                        </FrontSide>

                        <BackSide>
                            <span className="process-count">{val.processCount}</span>
                            <p className="desc">{val.ProcessDesc}</p>
                        </BackSide>
                    </Flippy>
                    </div>
                </div>
            ))}
          </Swiper>
        )
    }
}

export default WorkingProcess;