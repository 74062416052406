import React from 'react';
import './../css/component/pagination.css';
import {FaAngleLeft, FaAngleRight} from 'react-icons/fa'
import {Link} from 'react-router-dom'
function Pagination() {
    return (
        <div className="pagination-area">
            <ul className="pagination d-flex flex-wrap justify-content-center justify-content-lg-start">
                <li><Link href="#"><FaAngleLeft /></Link></li>
                <li><Link href="#">1</Link></li>
                <li><Link className="active" href="#">2</Link></li>
                <li><Link href="#">3</Link></li>
                <li><Link href="#">4</Link></li>
                <li><Link href="#">5</Link></li>
                <li><Link href="#"><FaAngleRight /></Link></li>
            </ul>
        </div>
    );
  }

export default Pagination;