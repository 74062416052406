import React, { Component } from 'react';
import './../css/component/banner-slider.css';
import { Link } from 'react-router-dom'
import Carousel from 'react-bootstrap/Carousel';
import ModalVideo from 'react-modal-video';
import { FaPlay } from "react-icons/fa";
import {Link as LinkScroll }  from 'react-scroll'
import { withTranslation } from 'react-i18next';
class BannerSlider extends Component {
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render() {
        const { t } = this.props;
        return (
            <div>
                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="assets/images/banner/slider/banner3.jpg"
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <div className="container">
                                <div className="slider-content">
                                    <div className="large-text">{t('bannerServicesTitle')}</div>
                                    <p className="small-text">{t('bannerServicesSubtitle')}</p>
                                    <div className="button-area text-center">
                                <LinkScroll to="service" className="custom-btn button-effect1">{t('bannerServicesButton')}</LinkScroll> 
                            </div>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="assets/images/banner/slider/banner2.jpg"
                            alt="Third slide"
                        />

                        <Carousel.Caption>
                        <div className="slider-content text-center">
                            <div className="large-text">{t('bannerContactTitle')}</div>
                            <p className="small-text">{t('bannerContactSubtitle')}</p>
                            <div className="button-area text-center">
                                <LinkScroll to="contact" className="custom-btn button-effect1">{t('bannerContactButton')}</LinkScroll> 
                            </div>
                        </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="assets/images/banner/slider/banner1.jpg"
                            alt="Third slide"
                        />

                        <Carousel.Caption>
                        <div className="slider-content text-center">
                            <div className="large-text">{t('bannerTechStackTitle')}</div>
                            <p className="small-text">{t('bannerTechStackSubtitle')}</p>
                            <div className="button-area text-center">
                                <LinkScroll to="project" className="custom-btn button-effect1">{t('bannerTechStackButton')}</LinkScroll> 
                            </div>
                        </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        )
    }
}

export default withTranslation()(BannerSlider);