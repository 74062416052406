import React, { Component } from 'react';
// import VideoModalBtn from './video-moddal-btn'
import ModalVideo from 'react-modal-video';
import { FaPlay } from "react-icons/fa";
import './../css/component/video-image.css';


class VideoImage extends Component {
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render() {
        return (
            <div className="video-image">
                <img className="thumbnail" src="assets/images/about/01.jpg" alt="image"/>
                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                <button className="video-button button-effect1" onClick={this.openModal}><FaPlay /></button>
            </div>
        )
    }
}

export default VideoImage;

