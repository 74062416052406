import React, { Component } from 'react';
import ContactForm from './../component/contact-form'
import ContactInfo from './../component/contact-info'
import './../css/contact-area.css';
import { withTranslation } from 'react-i18next';


class ContactArea extends Component {
    render() {
        return (
            <div id='contact' className="row contact-area">
                <div className="contact-info-area col-lg-4 p-0 order-lg-last d-flex align-items-center">
                    <ContactInfo />
                </div>
                <div className="contact-form-area col-lg-8 p-0 order-lg-first d-flex align-items-center">
                    <ContactForm />
                </div>
            </div>
        )
    }
}

export default withTranslation()(ContactArea);