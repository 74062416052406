import React, { Component, Fragment } from 'react';
import './../css/app.css';
import Header from './../sections/header';
import Banner from './../sections/banner';
import About from './../sections/about-section';
import WorkingProcessSection from './../sections/working-process-section';
import About2 from './../sections/about-section2'
import ServiceSection from './../sections/service-section'
import PortfolioSection from './../sections/portfolio-section'
import CustomerSatisfection from './../sections/customer-satisfection-section'
import BlogSection from './../sections/blog-section'
import GetStart from './../sections/getstart-section'
import Footer from './../sections/footer'
class DarkIndex extends Component {
  render(){
    
    return (
        <Fragment> 
            <div className="dark">
                <Header styleClass={'header style1 transparent-header'}/>
                <Banner bannerStyle={'banner-section'}></Banner>
                <About />
                <WorkingProcessSection />
                <About2 />
                <ServiceSection />
                <PortfolioSection />
                <CustomerSatisfection />
                <BlogSection />
                <GetStart />
                <Footer />
            </div>
        </Fragment> 
    );
  }
}

export default DarkIndex;