import React, { Component } from 'react'
import './../css/page-header.css'


class PageHeader extends Component {
    
    render() {
        return (
            // <section className="page-header style2 about">
            <section className={ ( this.props.styleClass ? this.props.styleClass : "") }>
                <div className="page-header-content">
                    <div className="container">
                        <div className="page-header-text">
                            <h2>{this.props.pageTitle}</h2>
                            <p className="mb-0">{this.props.pageDesc}</p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default PageHeader;
