import React, { Component } from 'react';
import './../css/component/contact-form.css';
import emailjs from 'emailjs-com';
import Popup  from './CustomViews/ContactPopUp';

import { withTranslation } from 'react-i18next';



class ContactForm extends Component {
    constructor(){
        super()
        this.state={
            nameValue: "",
            emailValue: "",
            numberValue: "",
            messageValue: "",
            showPopup: false
        }
        this.submitForm = this.submitForm.bind(this)
    }
    togglePopup() {  
        this.setState({  
             showPopup: !this.state.showPopup  
        });  
         }  
    submitForm(e){
        e.preventDefault();
        if(this.state.emailValue !== "" || this.state.messageValue !== "")
        {
            const templateId = "template_fwf3u66";
            const emailObject = {name: this.state.nameValue, email: this.state.emailValue, number: this.state.numberValue, message: this.state.messageValue};
            emailjs.sendForm('service_iy7hqky', 'template_fwf3u66', e.target, 'user_Wo0H4M8Zt6mpdjx6bmT9m').then((result) => {
                console.log(result.text);
            },
            (error) => {
                console.log(error.text);
            }
            )
            this.setState({showPopup: true, popUpValue: "Thank you for contacting with us. We will try to get back to you as soon as we can."});
            this.setState({nameValue: "", emailValue: "", numberValue: "", message: ""});
        }
        else
        {
            this.setState({showPopup: true, popUpValue: "There are some information missing, please double check it and try again."});
        }


    }
    render() {
        const { t } = this.props;
        return (
            <div>
            <form  onSubmit={this.submitForm} action="#" className="contact-form" mathod= "POST">
                <input onChange={ (e) => this.setState({nameValue: e.target.value})} type="text" name="name" placeholder={t('contactSectionName')}/>
                <input onChange={ (e) => this.setState({emailValue: e.target.value})}  type="text" name="email" placeholder="Email*"/>
                <input onChange={ (e) => this.setState({numberValue: e.target.value})}  type="number" name="phone" placeholder={t('contactSectionPhone')}/>
                <textarea onChange={ (e) => this.setState({messageValue: e.target.value})}  name="massage"></textarea>
                <input type="submit" className="submit" value="Submit Info"/>
            </form>
            {this.state.showPopup ?  
            <Popup
          text={this.state.popUpValue}  
          closePopup={this.togglePopup.bind(this)}  
            />  
            : null  
            }  
            </div>
        )
    }
}

export default withTranslation()(ContactForm);