import React, { Component, Fragment } from 'react';
import './../css/app.css';
import Header from './../sections/header';
import PageHeader from './../sections/page-header'
import SectionHeader from './../component/section-header'
import AboutProjectSection from './../sections/about-project-section'
import OurMission from './../sections/our-mission'
import TestimonialSection from './../sections/testimonial-section'
// import ClientLogoSection from './../sections/client-logo-section'
import ActionSection from './../sections/action-section'
import Footer from './../sections/footer'
// header section content
const title = <h2 className="title">New Start up<br />with competitive prices </h2>;
const desc = <p className="desc pb--30 mb-0">We are new start up, providing mobile applications and web sites for your needs. We focus on delivering high quality applications while trying to provide you with lowest price possible. Thanks to modern technologies like Xamarin Forms and React Native, we can build 2 applictions for the price of one. Both of those frameworks allows us to share code between 2 platforms. This allows us to finish the projects quicker and cheaper than our competitors.</p>
const styleClass = 'bz-section-header inner-style2';
class AboutPage extends Component {
  render(){
    
    return (
        <Fragment> 
            <div className="aboutpage innerpage">
                <Header styleClass={'header style2 transparent-header'}/>
                <PageHeader 
                    pageTitle={'About Page'} 
                    pageDesc={'We help your brand to be recognizable'}
                    styleClass={'page-header style2 about'}
                />
                <section className="inner-page about-page">
                    <div className="inner-page-header">
                        <div className="container pl-5 pr-5 p-lg-0">
                            <SectionHeader
                                title={ title }
                                desc={desc}
                                styleClass={styleClass}
                            />
                        </div>
                    </div>
                </section>
                <AboutProjectSection />
                <OurMission />
                <TestimonialSection />
                {/* <ClientLogoSection /> */}
                <ActionSection />
                <Footer />
            </div>
        </Fragment> 
    );
  }
}

export default AboutPage;