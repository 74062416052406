import React, { Component } from 'react';
import './../css/our-mission.css';
import { withTranslation } from 'react-i18next';
class OurMission extends Component {
    render() {
        const { t } = this.props;
        return (
            <section className="our-mission-section pt--80 pb--80">
                <div className="container container780 text-center">
                    <h2 className="title text-white mb-3"> {t('ourMissionSectionTitle')}</h2>
                    <p className="text-white mb-0">{t('ourMissiongSectionSubtitle')} </p>
                </div>
            </section>
        )
    }
}

export default withTranslation()(OurMission);