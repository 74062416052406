import React, { Component, useContext } from 'react';
import './../css/component/header-top.css';
import LocaleContext from '../LocaleContext'
import {FaPaperPlane, FaMobileAlt, FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaVimeo, FaSearch, FaAngleDown} from 'react-icons/fa'



class HeaderTop extends Component {
    searchTrigger() {
        document.querySelector('body').classList.add('search-opens')
    }
    render() {
        return (
            <div className="header-top d-none d-lg-flex" style={{background: '#ffffff80'}}>
                <div className="headertop-left">
                    <ul className="site-info m-0 p-0 list-unstyled">
                        <li><a href="tel:447713357361"><FaMobileAlt /> +447713357361</a></li>
                        <li><a href="mailto:info@bluehashstudio.com?Subject=Hello%20again" target="_top"><FaPaperPlane /> Info@bluehashstudio.com</a></li>
                    </ul>
                </div>

                <div className="headertop-right d-flex flex-wrap">

                    <div className="language-option">
                        <a className="language-trigger" onClick={this.props.ChangeLanugeToEnglish}>English <FaAngleDown/></a>
                        <div className="language-container">
                            <a onClick={this.props.ChangeLanugeToPolish}>Polski</a>
                        </div>
                    </div>

                    <div className="search-option"onClick={this.searchTrigger}>
                        <FaSearch />
                    </div>

                    <ul className="social-media-list d-flex m-0 p-0 list-unstyled">
                        <li><a href="https://www.facebook.com/fluxthemeOfficial/"><FaFacebookF /></a></li>
                        <li><a href="https://twitter.com/fluxtheme"><FaTwitter /></a></li>
                        <li><a href="https://www.linkedin.com/company/flux-theme/"><FaLinkedinIn /></a></li>
                        <li><a href="https://www.instagram.com/FluxThemeOfficial/"><FaInstagram /></a></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default HeaderTop;