import React, { Component } from 'react';
import HeaderTop from './../component/header-top'
import { Link, NavLink } from 'react-router-dom'
import {Link as LinkScroll }  from 'react-scroll'
import {FaAngleDown, FaAngleRight} from 'react-icons/fa'
import './../css/header.css';
import { withTranslation } from 'react-i18next';
class Header extends Component{
    
    searchTrigger() {
        document.querySelector('body').classList.add('search-opens')
    }
    menuTrigger() {
        document.querySelector('body').classList.add('open-mobile-menu')
    }
    closeMenuTrigger() {
        document.querySelector('body').classList.remove('open-mobile-menu')
    }

ChangeLanugeToPolish= () =>{
debugger
}
    render(){

        const { headerStyle } = this.props;
        const { t } = this.props;
        var elements = document.querySelectorAll('.has-childmenu > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("actives");
                    this.classList.toggle("open");
                }
            }
        }
        const style = {
            backgroundColor: '#008dd2',
            color: 'Red'
        }

        return(
            
            <header  className={ ( this.props.styleClass ? this.props.styleClass : "") }>
            <HeaderTop ChangeLanugeToPolish={this.props.ChangeLanugeToPolish} ChangeLanugeToEnglish={this.props.ChangeLanugeToEnglish}/>

            <div onClick={() => this.searchTrigger}  className="main-menu-area mega-menu-header animated">
                <div className="row m-0 align-items-center">
                    <div className="col-lg-2 p-0 d-flex align-items-center justify-content-between">
                        <div className="menu-bar d-lg-none" onClick={this.menuTrigger}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div className="col-lg-10 d-none d-lg-block p-0 d-lg-flex align-items-center justify-content-end justify-content-xl-end">
                        <div className="d-lg-flex flex-wrap justify-content-start">
                            <ul className="nav-menu d-lg-flex flex-wrap list-unstyled m-0 justify-content-center">
                                <li className="nav-item dropdown"><LinkScroll spy={true} smooth={true} to='home'>{t('homeHeaderButton')}</LinkScroll>

                                </li>
                                <li className="nav-item"><LinkScroll spy={true} smooth={true} to='about'>{t('aboutHeaderButton')}</LinkScroll></li>
                                <li className="dropdown nav-item"><LinkScroll spy={true} smooth={true} to='service'>{t('serviceHeaderButton')}</LinkScroll>
                                </li>
                                <li className="nav-item"><LinkScroll spy={true} smooth={true} to="contact">{t('contactHeaderButton')}</LinkScroll></li>
                            </ul>
                            <div className="logo">
                            <Link className="navbar-brand" to="/"><img className="lazy" src="assets/images/logo.png" alt="logo"/></Link>
                            <Link className="navbar-brand navbar-brand2" to="/"><img className="lazy" src="assets/images/logo.png" alt="logo"/></Link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile-menu-area d-lg-none">
                <div className="m-menu-header">
                    <span className="close-bar" onClick={this.closeMenuTrigger}>
                        <span></span>
                        <span></span>
                    </span>
                </div>
                <div className="mobile-menu">
                    <ul className="m-menu">
                        <li className="has-childmenu"><NavLink to="#">Home <span className="arrow"><FaAngleRight /></span></NavLink>
                            <ul className="mobile-submenu submenu">
                                <li><NavLink activeStyle={style} onClick={this.closeMenuTrigger} to="/business">Business</NavLink></li>
                                <li><NavLink activeStyle={style} onClick={this.closeMenuTrigger} to="/dark-index">Business Dark</NavLink></li>
                            </ul>
                        </li>
                        <li className="has-childmenu"><NavLink to="/aboutpage"onClick={this.closeMenuTrigger}>About</NavLink></li>
                        <li className="has-childmenu"><NavLink to="#">Portfolio <span className="arrow"><FaAngleRight /></span></NavLink>
                            <ul className="mobile-submenu submenu">
                                <li><NavLink activeStyle={style} onClick={this.closeMenuTrigger} to="/portfolio-page">Portfolio</NavLink></li>
                                <li><NavLink activeStyle={style} onClick={this.closeMenuTrigger} to="/portfolio-single">Portfolio Single</NavLink></li>
                            </ul>
                        </li>
                        <li className="has-childmenu"><NavLink to="#">Blog <span className="arrow"><FaAngleRight /></span></NavLink>
                            <ul className="mobile-submenu submenu">
                                <li><NavLink activeStyle={style} onClick={this.closeMenuTrigger} to="/blog-page">Blog</NavLink></li>
                                <li><NavLink activeStyle={style} onClick={this.closeMenuTrigger} to="/blog-detail">Blog Single</NavLink></li>
                            </ul>
                        </li>
                        <li><NavLink to="contact-page"onClick={this.closeMenuTrigger}>Contact</NavLink></li>
                    </ul>
                </div>
            </div>
        </header>
        )
    }
}
export default withTranslation()(Header)
