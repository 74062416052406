import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import './../css/get-start.css'


class GetStart extends Component {
    
    render() {
        return (
            <section className="bz-action-section pt--80 pb--80 pt_lg--120 pb_lg--120">
                <div className="col-11 col-lg-5 m-auto">
                    <div className="action-content text-center text-lg-left">
                        <p className="subtitle">So What's Next</p>
                        <h2 className="title">Get Started Now! <Link to="#">Purchase</Link></h2>
                    </div>
                </div>
            </section>
        )
    }
}

export default GetStart;