import React, { Component } from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import './../css/component/okimage.css';
import 'swiper/swiper.scss';

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const imageList = [
    {
        imagUrl: "01",
        imageAlt: 'image1'
    },
    {
        imagUrl: "02",
        imageAlt: 'image2'
    },
    {
        imagUrl: "03",
        imageAlt: 'image3'
    },

]

class OkImageSlider extends Component {
    render() {
        return (
            <Swiper className="bz-about-slide-container"
                spaceBetween={0}
                slidesPerView={3}
                centeredSlides={true}
                grabCursor={true}
                loop={true}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                navigation
                pagination={{ 
                    clickable: true,
                    el: '.swiper-pagination',
                    type: 'fraction'
                }}
                >
                {imageList.map((val , i) => (
                    <SwiperSlide key={i}>
                        <div className="okimage">
                            <img
                                src={`assets/images/post/${val.imagUrl}.png`} alt={`${val.imageAlt}`}
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        )
    }
}

export default OkImageSlider;