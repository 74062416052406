import React, { Component } from 'react';
import VideoImage from './../component/video-image'
import SectionHeader from './../component/section-header'
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import { FaPlay } from "react-icons/fa";
import './../css/component/video-image.css';
// import { FaAngleDoubleRight } from "react-icons/fa";
import './../css/banner.css';


// header section content
const title = <h2 className="title">Benefits you get are far more precious than money</h2>;
const subtitle = <h6 className="subtitle">Why Choose Us</h6>;
const desc = <p className="desc pb--30 mb-0">If you want your business to give the best digital outlook, Okala theme is the best option. You have the total power of customization, 24/7 technical support, multilingualism, hosting facilities, etc. You are always welcome to visit Okala templates and pick your desired one.</p>
const styleClass = 'bz-section-header';
const link = <Link to="aboutpage" className="da-custom-btn btn-border-radius40"><span>Get Started</span></Link>

class About2 extends Component {
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render() {
        return (
            <section className="aboutus style2 borderbottom pt--60 pb--60 pt_lg--100 pb_lg--100">
                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 order-lg-last mb--40 mb-lg-0 p-lg-0">
                            <SectionHeader 
                                subtitle={ subtitle }
                                title={ title }
                                desc={desc}
                                styleClass={styleClass}
                                link={link}
                            />
                        </div>
                        <div className="col-lg-7 mb--40 mb-lg-0  p-lg-0 order-lg-first">
                            <div className="video-image">
                                <img className="thumbnail" src="assets/images/about/01.jpg" alt="image"/>
                                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                <button className="video-button button-effect1" onClick={this.openModal}><FaPlay /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default About2;