import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import App from './home/App'
import Business from './home/business'
import DarkIndex from './home/dark-index'
import AboutPage from './pages/aboutpage'
import PortfolioPage from './pages/portfolio-page'
import PortfolioSingle from './pages/portfolio-single'
import BlogGridPage from './pages/blog-page'
import BlogDetail from './pages/blog-detail'
import ContactPage from './pages/contact-page'
import PricingPage from './pages/pricing-page'
import SearchBody from './component/search-body'
import ScrollToTop from "react-scroll-to-top";
import ScrollTop from './component/scrollTop';
import { BrowserRouter, Switch, Route  } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'


class Root extends Component{
  render(){
    return(
      <BrowserRouter>
        <SearchBody />
        <ScrollTop />
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={App}/>
          <Route exact path={`${process.env.PUBLIC_URL}/business`} component={Business}/>
          <Route exact path={`${process.env.PUBLIC_URL}/dark-index`} component={DarkIndex}/>
          <Route exact path={`${process.env.PUBLIC_URL}/aboutpage`} component={AboutPage}/>
          <Route exact path={`${process.env.PUBLIC_URL}/portfolio-page`} component={PortfolioPage}/>
          <Route exact path={`${process.env.PUBLIC_URL}/portfolio-single`} component={PortfolioSingle}/>
          <Route exact path={`${process.env.PUBLIC_URL}/blog-page`} component={BlogGridPage}/>
          <Route exact path={`${process.env.PUBLIC_URL}/blog-detail`} component={BlogDetail}/>
          <Route exact path={`${process.env.PUBLIC_URL}/contact-page`} component={ContactPage}/>
          <Route exact path={`${process.env.PUBLIC_URL}/pricing-page`} component={PricingPage}/>
        </Switch>
        <div className="backto-top">
          <ScrollToTop smooth>UP</ScrollToTop>
        </div>
      </BrowserRouter>
    )
  }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
