import React, { Component } from 'react';
import OkImageSlider from './../component/ok-image-slider'
import SectionHeader from './../component/section-header'
import { Link } from 'react-router-dom';
import { FaAngleDoubleRight } from "react-icons/fa";
import './../css/banner.css';
import { withTranslation } from 'react-i18next';
// header section content
const title = <h2 className="title">#UMIEJĘTNOŚĆI,  #PASJA , #KREATYWNOŚĆ</h2>;
const subtitle = <h6 className="subtitle">Dlaczego My?</h6>;
const desc = <p className="desc pb--30 mb-0">Jesteśmy startupem świadczącym usługi w zakresie tworzenia aplikacji mobilnych,stron WWW oraz modelowania 3D. W naszych projektach stawiamy na kreatywność i funkcjonalność wykorzystując przy tym najnowsze technologie. Działamy na rynku europejskim gdzie tworzymy rozwiązania dla klientów z różnych branż. Zmieniamy pomysły w świetnie zaprojektowane produkty. Stawiamy na rozwój a zadowolenie i zaufanie klienta jest dla nas rzeczą najważniejszą.</p>
const styleClass = 'bz-section-header';


class About extends Component {
    render() {
        const { t } = this.props;
        return (
            <section id='about' className="aboutus style1 borderbottom pt--60 pb--70 pt_lg--100 pb_lg--110">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-lg-6 order-lg-last mb--60 mb-lg-0">
                            <SectionHeader 
                                subtitle={ <h6 className="subtitle">{t('aboutUsSectionWhyUs')}</h6> }
                                title={ <h2 className="title">{t('aboutUsSectionHashtags')}</h2> }
                                desc={<p className="desc pb--30 mb-0">{t('aboutUsSectionParagraph')}</p>}
                                styleClass={styleClass}
                            />
                        </div>
                        <div className="col-lg-6 order-lg-first">
                            <div className="bz-about-slider">
                                <OkImageSlider />
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
        )
    }
}

export default withTranslation()(About);