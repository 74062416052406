import React, { Component, Fragment, useRef, Suspense, useState } from 'react';
import './../css/app.css';
import i18n from './i18n';
import Header from './../sections/header';
import Banner from './../sections/banner';
import About from './../sections/about-section';
import WorkingProcessSection from './../sections/working-process-section';
import About2 from './../sections/about-section2'
import ServiceSection from './../sections/service-section'
import PortfolioSection from './../sections/portfolio-section'
import CustomerSatisfection from './../sections/customer-satisfection-section'
import BlogSection from './../sections/blog-section'
import GetStart from './../sections/getstart-section'
import Footer from './../sections/footer'
import AboutProjectSection from './../sections/about-project-section'
import OurMission from './../sections/our-mission'
import TestimonialSection from './../sections/testimonial-section'
import ContactArea from './../sections/contact-area'
import Loading from './../component/Loading'
import LocaleContext from './../LocaleContext';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preferedLocale: 'pl'
    }
  
  }
  ChangeLanugeToPolish = () =>{
    this.setState({
      preferredLocale: "pl",
    });
  }
  ChangeLanugeToEnglish = () =>{
    this.setState({
      preferredLocale: "en",
    });
  }
  render(){
    return (
      <LocaleContext.Provider locale={"pl"}>
      <Suspense fallback={<Loading />}>
        <Fragment> 
            <div className="App">
                <Header ChangeLanugeToPolish={this.ChangeLanugeToPolish} ChangeLanugeToEnglish={this.ChangeLanugeToEnglish} styleClass={'header style1 header--fixed transparent-header'}/>
                <Banner  bannerStyle={'banner-section'}></Banner>
                <About />
                <ServiceSection />
                <PortfolioSection />
                <AboutProjectSection />
                <OurMission />
                <TestimonialSection />

                <div className="container">
                        <ContactArea />
                    </div>
                {/* <ClientLogoSection /> */}
                <Footer />
            </div>
        </Fragment> 
        </Suspense>
        </LocaleContext.Provider>
    );
  }
}

export default App;