import React, { Component } from 'react';
import SectionHeader from './../component/section-header'
import PortfolioContainer from './../component/prorfolio-container'
import 'react-tabs/style/react-tabs.css';
import './../css/portfolio.css';



// header section content
const title = <h2 className="title">Project Showcase</h2>;
const styleClass = 'bz-section-header  center-styled text-center';

class PortfolioSection extends Component {
    render() {
        return (
            <section className="bz-project-section borderbottom pt--60 pb--60 pt_lg--100 pb_lg--100">
                <div className="container p-0">
                    <SectionHeader 
                        title={ title }
                        styleClass={styleClass}
                    />
                </div>
        
                <div className="section-wrapper">
                    <PortfolioContainer />
                </div>
            </section>
        )
    }
}

export default PortfolioSection;