import React, { Component } from 'react';
import SectionHeader from './../component/section-header';
import WorkingProcess from './../component/working-process';
import { Link } from 'react-router-dom';
import { FaAngleDoubleRight } from "react-icons/fa";
import './../css/banner.css';


// header section content
const title = <h2 className="title">Benefits you get are far more <br/>precious than money</h2>;
const subtitle = <h6 className="subtitle">Why Choose Us</h6>;
const desc = <p className="desc pb-0 mb-0">Okala is a WordPress theme template. If you want your dream business to give a magnificent digital look, then the Okala theme is the best option. Okala will provide you specialties that no other theme can provide. You will get total power of customization, 24/7 technical support, multilingualism, hosting and so on. We, Okala are a strategic, creative team you can rely on. You are welcome to visit Okala templates and pick your desired website theme</p>
const styleClass = 'bz-section-header mb--60';
const link = <Link to="aboutpage" className="btn-transparent">Learn More <FaAngleDoubleRight /></Link>

class WorkingProcessSection extends Component {
    render() {
        return (
            <section className="working-process-section bg-ash borderbottom pt--60 pb--100 pt_lg--100 pb_lg--140">
                <div className="container">
                    <SectionHeader 
                        subtitle={ subtitle }
                        title={ title }
                        desc={desc}
                        styleClass={styleClass}
                    />
                </div>
        
                <div className="container">
                    <div className="bz-working-process">
                        <WorkingProcess />
                    </div>
                </div>
            </section>
        )
    }
}

export default WorkingProcessSection;