import React, { Component } from 'react';
import SectionHeader from './../component/section-header'
import { FaCheckCircle } from "react-icons/fa"
import { Link } from 'react-router-dom'
import 'react-tabs/style/react-tabs.css'
import './../css/pricing-section.css'



const PriceItemList = [
    {
        catagory: 'Basic',
        price: 125,
        dollarSign: '$',
        duration: 'Per Month, AUD Inlc GST.',
        btnText: 'Order Now',
        PriceFeatureList: [
            {
                featureText: 'Unlimited Products'
            },
            {
                featureText: '500 GB Bandwidth'
            },
            {
                featureText: 'Mobile Website and Store'
            },
            {
                featureText: 'Custom Domain Free'
            },
            {
                featureText: '24/7 Customer Support'
            },
            {
                featureText: 'Unlimited Staff'
            },
        ]
    },
    {
        catagory: 'Professional',
        price: 250,
        dollarSign: '$',
        duration: 'Per Month, AUD Inlc GST.',
        btnText: 'Order Now',
        PriceFeatureList: [
            {
                featureText: 'Unlimited Products'
            },
            {
                featureText: '500 GB Bandwidth'
            },
            {
                featureText: 'Mobile Website and Store'
            },
            {
                featureText: 'Custom Domain Free'
            },
            {
                featureText: '24/7 Customer Support'
            },
            {
                featureText: 'Unlimited Staff'
            },
        ]
    },
    {
        catagory: 'Business',
        price: 285,
        dollarSign: '$',
        duration: 'Per Month, AUD Inlc GST.',
        btnText: 'Order Now',
        PriceFeatureList: [
            {
                featureText: 'Unlimited Products'
            },
            {
                featureText: '500 GB Bandwidth'
            },
            {
                featureText: 'Mobile Website and Store'
            },
            {
                featureText: 'Custom Domain Free'
            },
            {
                featureText: '24/7 Customer Support'
            },
            {
                featureText: 'Unlimited Staff'
            },
        ]
    }
]


// header section content
const title = <h2 className="title">Okala Template comes with<br/>awesome widgets.</h2>;
const subtitle = <h6 className="subtitle">Over 8000 Customers Trust Us, Why won’t You?</h6>;
const desc = <p className="desc pb--30 mb-0">Okala will provide you specialties that no other theme can provide. You will get the total power of customization, 24/7 technical support, multilingualism, hosting facilities and it’s budget friendly</p>
const styleClass = 'bz-section-header text-center center-styled';

class PricingSection extends Component {
    render() {
        return (
            <section className="bz-pricing-section bg-ash borderbottom  pt--60 pb--60 pt_lg--100 pb_lg--100">
                <div className="col-11 col-lg-5 m-auto pb--30 pb_lg--60">
                <SectionHeader 
                    subtitle={ subtitle }
                    title={ title }
                    desc={desc}
                    styleClass={styleClass}
                />
                </div>
                <div className="container">
                    <div className="row flex-column">
                        {PriceItemList.map((value , i ) => (
                            <div className="bz-pricing-item" key={i}>
                                <div className="pricing-head">
                                    <p className="name">{value.catagory}</p>
                                    <h2 className="price">{value.dollarSign}{value.price}</h2>
                                    <p className="per-time">{value.duration}</p>
                                </div>
                                <div className="pricing-body">
                                    <ul className="item-listing">
                                        <li><FaCheckCircle />Teams of 3-10 people</li>
                                        <li><FaCheckCircle />100 GB secure storage</li>
                                        <li><FaCheckCircle />Mobile, sync and share</li>
                                    </ul>
                                    <ul className="item-listing">
                                        <li><FaCheckCircle />User management</li>
                                        <li><FaCheckCircle />Box content API</li>
                                    </ul>
                                </div>
                                <div className="pricing-footer">
                                    <Link to="/contact-page" className="da-custom-btn btn-border-radius40"><span>Purchase</span></Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        )
    }
}

export default PricingSection;