import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import './../css/portfolio-single.css';
import './../css/portfolio.css';
import Header from './../sections/header';
import PageHeader from './../sections/page-header'
import ActionSection from './../sections/action-section'
import Footer from './../sections/footer'
// header section content
const title = <h2 className="title">We love to craft<br />Pixel perfect output for all</h2>;
const desc = <p className="desc pb--30 mb-0">We are a strategic, creative studio based out of Savannah, Georgia. Our close team of innovative professionals is passionate about partnering with good people and organizations to design exceptional custom solutions. We approach each project and design challenge love to help.</p>
const styleClass = 'bz-section-header inner-style2';
class PortfolioSingle extends Component {
  render(){
    
    return (
        <Fragment> 
            <Header styleClass={'header style2 transparent-header'}/>
            <PageHeader 
                pageTitle={'Portfolio Single'} 
                pageDesc={'Let\'s Talk About Your Project'}
                styleClass={'page-header style2 portfolio'}
            />
            <section className="inner-page portfolio-single pt--70 pt_lg--120">
                <div className="container">
                    <div className="entry-single">
                        <div className="thumb">
                            <img src="assets/images/portfolio/single-image.jpg" alt="image"/>
                        </div>
                        <div className="content pt_lg--60 pb--60">
                            <div className="row align-items-center">    
                                <div className="col-lg-8">   
                                    <h3 className="font-weight-bolder">Project Info</h3>
                                    <p>Illum molestias cupiditate eveniet dolore obcaecati voluptatibus est quos eos id recusandae officia. Cupiditate, voluptates quibusdam ipsum vel corporis laboriosam id est doloremque? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis, dolores, facere, corrupti delectus ex quidem adipisci tempore. Illum molestias cupiditate eveniet dolore obcaecati voluptatibus est quos eos id recusandae officia. Cupiditate, voluptates quibusdam ipsum vel corporis laboriosam id est doloremque? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis, dolores, facere, corrupti delectus ex quidem adipisci tempore.</p>
                                </div>
                                <div className="col-lg-4">   
                                    <div className="project-details-area">   
                                        <ul className="project-details list-unstyled pl-0 mb--20">
                                            <li><span><i className="fas fa-user"></i> Created By</span>: John Doe</li>
                                            <li><span><i className="fas fa-calendar-alt"></i> Complete Date</span>: 01-04-2020</li>
                                            <li><span><i className="fas fa-link"></i> Client</span>: 3devsit</li>
                                        </ul> 
                                        <ul className="social-media-list d-flex flex-wrap m-0 p-0 list-unstyled">
                                            <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="#"><i className="fab fa-behance"></i></a></li>
                                            <li><a href="#"><i className="fab fa-vimeo-v"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="related-gallery bz-project-section">
                        <h3 className="font-weight-bolder mb--20">Related Gallery</h3>
                        <div className="project-container row pb--60 pb_lg--80 pl-0 pr-0">
                            <div className="col-md-6 col-lg-4">
                                <div className="project-item">
                                    <div className="project-thumb">
                                        <Link to="portfolio-single"><img src="assets/images/project/01.jpg" alt="project"/></Link>
                                        <div className="project-content">
                                            <div className="project-content-inner">
                                                <p className="cata">Development</p>
                                                <h6 className="title"><Link to="portfolio-single">Simple &amp; Effective</Link></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <div className="project-item">
                                    <div className="project-thumb">
                                        <Link to="portfolio-single"><img src="assets/images/project/02.jpg" alt="project"/></Link>
                                        <div className="project-content">
                                            <div className="project-content-inner">
                                                <p className="cata">Development</p>
                                                <h6 className="title"><Link to="portfolio-single">Simple &amp; Effective</Link></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <div className="project-item">
                                    <div className="project-thumb">
                                        <Link to="portfolio-single"><img src="assets/images/project/03.jpg" alt="project"/></Link>
                                        <div className="project-content">
                                            <div className="project-content-inner">
                                                <p className="cata">Development</p>
                                                <h6 className="title"><Link to="portfolio-single">Simple &amp; Effective</Link></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ActionSection />
            <Footer />
        </Fragment> 
    );
  }
}

export default PortfolioSingle;