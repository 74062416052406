import React from 'react';
import {Link} from 'react-router-dom'
function CommentList() {
    return (
        <ul className="comments-list">
            <li className="comments">
                <div className="thumb">
                    <img src="assets/images/single-page/comment/01.jpg" alt="thumb"/>
                </div>
                <div className="content">
                    <div className="comment-heading">
                        <Link className="name" to="#">Johan Miami</Link> <span>Jan 18, 2017 at 05:30 AM</span>
                        <Link to="#" className="reply">Reply</Link>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat </p>

                </div>
            </li>

            <li className="comments">
                <div className="thumb">
                    <img src="assets/images/single-page/comment/02.jpg" alt="thumb"/>
                </div>
                <div className="content">
                    <div className="comment-heading">
                        <Link className="name" to="#">Johan Miami</Link> <span>Jan 18, 2017 at 05:30 AM</span>
                        <Link to="#" className="reply">Reply</Link>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat </p>
                </div>

                <ul className="comment-sub-list">
                    <li className="comments">
                        <div className="thumb">
                            <img src="assets/images/single-page/comment/03.jpg" alt="thumb"/>
                        </div>
                        <div className="content">
                            <div className="comment-heading">
                                <Link className="name" to="#">Johan Miami</Link> <span>Jan 18, 2017 at 05:30 AM</span>
                                <Link to="#" className="reply">Reply</Link>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat </p>
                        </div>
                        <ul className="comment-sub-list">
                            <li className="comments">
                                <div className="thumb">
                                    <img src="assets/images/single-page/comment/01.jpg" alt="thumb"/>
                                </div>
                                <div className="content">
                                    <div className="comment-heading">
                                        <Link className="name" to="#">Johan Miami</Link> <span>Jan 18, 2017 at 05:30 AM</span>
                                        <Link to="#" className="reply">Reply</Link>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat </p>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>

            <li className="comments">
                <div className="thumb">
                    <img src="assets/images/single-page/comment/01.jpg" alt="thumb"/>
                </div>
                <div className="content">
                    <div className="comment-heading">
                        <Link className="name" to="#">Johan Miami</Link> <span>Posted On Jan 18, 2017 at 05:30 AM</span>
                        <Link to="#" className="reply">Reply</Link>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat </p>
                </div>
            </li>
        </ul>
    );
  }

export default CommentList;