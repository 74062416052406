import React, { Component } from 'react';
import SectionHeader from './../component/section-header'
import Testimonial from './../component/testimonial'
import './../css/testimonial-section.css';

import { withTranslation } from 'react-i18next';
// header section content
const title = <h2 className="title">What People Say</h2>;
const subtitle = <h6 className="subtitle">Testimonial</h6>;
const desc = <p className="desc pb--30 mb-0">Squarespace powers millions of websites across hundreds<br />of industries for people just like you.</p>
const styleClass = 'bz-section-header text-center pb--30';

class TestimonialSection extends Component {
    render() {
        const { t } = this.props;
        return (
            <section className="da-testimonial-section bg-ash-color pt--60 pb--60 pt_lg--100 pb_lg--100">
                <div className="container">
                <SectionHeader 
                    subtitle={ <h6 className="subtitle">{t('testimonialSectionTitle')}</h6> }
                    title={ <h2 className="title">{t('testimonialSectionSubtitle')}</h2> }
                    desc={<p className="desc pb--30 mb-0"> {t('testimonialSectionSmallSubtitle')}</p>}
                    styleClass={styleClass}
                />
                </div>
                <div className="container">
                    <Testimonial />
                </div>
            </section>
        )
    }
}

export default withTranslation()(TestimonialSection);