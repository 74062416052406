import React from 'react';

class Popup extends React.Component {  
  render(props) {  
return (  
<div style={{display: 'flex', position: 'center'}}>
<i>{this.props.text}</i>
</div>  
);  
}  
}  

export default Popup;