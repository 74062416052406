import React from 'react';
function CommentResponseForm() {
    return (
        <div className="comment-response">
            <form action="#">
                <div className="form-input  row">
                    <div className="col-lg-4">
                        <input type="text" className="name" name="name" placeholder="Your Name"/>
                    </div>
                    <div className="col-lg-4">
                        <input type="text" className="email" name="E-mail" placeholder="Your Email"/>
                    </div>
                    <div className="col-lg-4">
                        <input type="text" className="email" name="website" placeholder="Website"/>
                    </div>



                </div>
                <textarea name="massage" id="massage" placeholder="Your Massage"></textarea>

                <input type="submit" value="Submit"/>
            </form>
        </div>
    );
  }

export default CommentResponseForm;