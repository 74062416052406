import React, { Component, Fragment } from 'react';
import './../css/blog-detail.css';
import Header from './../sections/header';
import PageHeader from './../sections/page-header'
import CommentList from './../component/comment-list'
import CommentResponseForm from './../component/comment-respons'
import ActionSection from './../sections/action-section'
import Footer from './../sections/footer'
import {FaHeart, FaShareAlt} from 'react-icons/fa'
import {Link} from 'react-router-dom'
// header section content
const title = <h2 className="title">Latest<br />From the Blog</h2>;
const desc = <p className="desc pb--30 mb-0">We are a strategic, creative studio based out of Savannah, Georgia. Our close team of innovative professionals is passionate about partnering with good people and organizations to design exceptional custom solutions. We approach each project and design challenge love to help.</p>
const styleClass = 'bz-section-header inner-style2';
class BlogDetail extends Component {
  render(){
    
    return (
        <Fragment> 
            <Header styleClass={'header style2 transparent-header'}/>
            <PageHeader 
                pageTitle={'Blog Single'} 
                pageDesc={'We help your brand to be recognizable'}
                styleClass={'page-header style2 blog'}
            />
            <div className="inner-page single-page borderbottom">
                <div className="container">
                    <div className="entry-content">
                        <div className="post-content">
                            <h1 className="title">Lorem ipsum dolor sit amet</h1>
                            <ul className="meta-post border-style d-flex flex-wrap list-unstyled">
                                <li>By <Link className="admin" to="#">Admin</Link></li>
                                <li>4 February, 2019</li>
                                <li><Link to="#">26 comments</Link></li>
                            </ul>
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Claritas est etiam, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum formas humanitatis per seacula quarta decima et quinta decima. Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes in futurum.</p>
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Claritas est etiam, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum formas humanitatis per seacula quarta decima et quinta decima. Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes in futurum.</p>
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Claritas est etiam, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum formas humanitatis per seacula quarta decima et quinta decima. Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes in futurum.</p>
                        </div>
                        <div className="post-thumb">
                            <img 
                                src="assets/images/single-page/01.jpg" 
                                alt="thumb" 
                            />
                        </div>
                        <div className="post-content">
                            <blockquote>
                                <p>Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum est notare quam littera gothica.</p>
                                <Link to="#" className="b-au">Frank Smith <span>company inco</span></Link>
                                <i className=" qoute-icon lni-quotation"></i>
                            </blockquote>
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Claritas est etiam, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum formas humanitatis per seacula quarta decima et quinta decima. Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes in futurum.</p>
                        </div>
                        <div className="entry-footer d-flex flex-wrap align-items-center justify-content-between align-items-center">
                            <ul className="tags list-unstyled pl-0 d-flex flex-wrap align-items-center mb-5 mb-lg-0">
                                <li>Tags:</li>
                                <li><Link to="#">Mockups</Link></li>
                                <li><Link to="#">Arts</Link></li>
                                <li><Link to="#">WordPress</Link></li>
                                <li><Link to="#">UI/UX</Link></li>
                                <li><Link to="#">Joomla</Link></li>
                            </ul>
                            <ul className="like-share list-unstyled pl-0 d-flex flex-wrap align-items-center m-0">
                                <li><Link to="#"><FaHeart /></Link></li>
                                <li><Link to="#"><FaShareAlt /></Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="mas-section">
                        <h6 className="section-title"><span>05</span> Comments</h6>
                        <CommentList />
                    </div>


                    <div className="mas-section mb--60 mb-0">
                        <h4 className="section-title">Leave a Comment
                        </h4>
                        <CommentResponseForm />
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment> 
    );
  }
}

export default BlogDetail;