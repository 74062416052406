import React, { Component, Fragment } from 'react';
import './../css/app.css';
import Header from './../sections/header';
import PageHeader from './../sections/page-header'
import PricingSection from './../sections/pricing-section'
import ActionSection from './../sections/action-section'
import Footer from './../sections/footer'
class PricingPage extends Component {
  render(){
    
    return (
        <Fragment> 
            <div className="aboutpage innerpage">
                <Header styleClass={'header style2 transparent-header'}/>
                <PageHeader 
                    pageTitle={'Pricing Page'} 
                    pageDesc={'We help your brand to be recognizable'}
                    styleClass={'page-header style2 contact-page'}
                />
                <PricingSection />
                <Footer />
            </div>
        </Fragment> 
    );
  }
}

export default PricingPage;