import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BlogGridContent from './blog-grid-content'
import Flippy, { FrontSide, BackSide } from 'react-flippy'
import './../../css/blog-section.css';



class BlogGrid3Col extends Component {
    render() {
        const PostList = BlogGridContent.slice(0 , this.props.itemNumber);
        return (
            <div className="row hover-effect-parent4 justify-content-center justify-content-lg-start">
                {PostList.map((val,i) => (
                    <div className="col-lg-4" key={i}>
                        <div className="post-item flip-style">
                            <div className="post-thumb">
                                <Link to="#">
                                <img
                                    src={`${val.imgUrl}`} 
                                    alt={`${val.imgAlt}`}
                                    />
                                </Link>
                                <div className="post-content">
                                    <Flippy
                                        flipOnHover={true}
                                        flipOnClick={true}
                                        flipDirection="vertical"
                                        ref={(r) => this.flippy = r}
                                        style={{ width: '100%', height: '150px' }}
                                    >
                                        <FrontSide>
                                            <ul className="meta-post line-style">
                                                <li>{val.postedOn}</li>
                                            </ul>
                                            <h6 className="title"><Link to="">{val.title}</Link></h6>
                                        </FrontSide>

                                        <BackSide>
                                            <Link to="/blog-detail" className="read-more">Read More</Link>
                                        </BackSide>
                                    </Flippy>
                                </div>
                            </div>
                        </div>
                    </div>
                    ))}
            </div>
            
        )
    }
}

export default BlogGrid3Col;