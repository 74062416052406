
const BlogGridContent = [
    {
        imgUrl: "assets/images/post/14.jpg",
        imgAlt: 'thumb1',
        postedOn: 'November 1, 2019',
        title: 'New York saw its highest single-day increase in deaths',
        btnText: 'Read More'
    },
    {
        imgUrl: "assets/images/post/11.jpg",
        imgAlt: 'thumb2',
        postedOn: 'December 15, 2019',
        title: 'A shortage of several hundred ventilators in New York City',
        btnText: 'Read More'
    },
    {
        imgUrl: "assets/images/post/03.jpg",
        imgAlt: 'thumb3',
        postedOn: 'August 30, 2019',
        title: 'The comments from Dr Fauci, who heads the National Institute',
        btnText: 'Read More'
    },
    {
        imgUrl: "assets/images/post/04.jpg",
        imgAlt: 'thumb1',
        postedOn: 'July 1, 2019',
        title: 'Covid-19 is carried in airborne droplets from people coughing',
        btnText: 'Read More'
    },
    {
        imgUrl: "assets/images/post/05.jpg",
        imgAlt: 'thumb2',
        postedOn: 'May 15, 2019',
        title: 'The WHO advises that ordinary face masks are only effective',
        btnText: 'Read More'
    },
    {
        imgUrl: "assets/images/post/06.jpg",
        imgAlt: 'thumb3',
        postedOn: 'June 1, 2019',
        title: 'Masks may also help lower the risk of individuals catching the virus ',
        btnText: 'Read More'
    },
    {
        imgUrl: "assets/images/post/07.jpg",
        imgAlt: 'thumb1',
        postedOn: 'March 12, 2019',
        title: 'Meanwhile, residents in Laredo, Texas will now face',
        btnText: 'Read More'
    },
    {
        imgUrl: "assets/images/post/10.jpg",
        imgAlt: 'thumb2',
        postedOn: 'August 5, 2019',
        title: 'Mr. Hogan has also leaned on his wife',
        btnText: 'Read More'
    },
    {
        imgUrl: "assets/images/post/08.jpg",
        imgAlt: 'thumb3',
        postedOn: 'November 2, 2019',
        title: 'Hospitals and morgues in New York are struggling to cope',
        btnText: 'Read More'
    }
]


export default BlogGridContent;